import { useMemo } from 'react'

import { makeStyles, Theme, Typography } from '@material-ui/core'
import moment from 'moment'
import PanelPage from 'support/components/panel/PanelPage'
import PlanoEnum from 'support/domain/negocio/PlanoEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'

interface CustomTheme extends Theme {
  container: {
    card: () => object
  }
  palette: Theme['palette'] & {
    text: {
      negative: string
    }
  }
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  errorText: {
    color: theme.palette.text.negative
  },
  containerPlano: {
    ...theme.container.card(),
    height: 'calc(100% - 40px)',
    padding: 20
  },
  colunaDataHora: {
    flexGrow: 1,
    maxWidth: 142,
    minWidth: 142
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 110,
    minWidth: 110
  },
  colunaSituacao: {
    flexGrow: 1,
    maxWidth: 80,
    minWidth: 80
  },
  containerTextoAcessoNegado: {
    padding: '8px 14px',
    borderRadius: 4,
    backgroundColor: 'rgb(0,0,0,0.04)',
    marginBottom: 32
  },
  paperInformacoesPlanoAvaliacao: {
    marginTop: 8
  },
  paperInformacoesPlanoContratado: {
    ...theme.container.card()
  }
}))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DescricaoValidade({ negocioSessao }: { negocioSessao: any }) {
  const classes = useStyles()
  const dataVencimentoPlanoAsMoment = converterDataIntParaMoment(negocioSessao.dataVencimentoPlano).startOf('day')
  const diasParaVencimento = dataVencimentoPlanoAsMoment.diff(moment().startOf('day'), 'days')

  if (PlanoEnum.AVALIACAO.id === negocioSessao.plano.id) {
    if (diasParaVencimento >= 0) {
      return (
        <>
          Fique a vontade e teste o sistema gratuitamente até <b>{dataVencimentoPlanoAsMoment.format('DD/MMM/YY')}</b>.
        </>
      )
    }

    return (
      <>
        <b className={classes.errorText}>O período de teste terminou em {dataVencimentoPlanoAsMoment.format('DD/MMM/YY')}</b>.
      </>
    )
  }

  if (diasParaVencimento >= 0) {
    return <>Data de vencimento: {dataVencimentoPlanoAsMoment.format('DD/MMM/YY')}</>
  }

  return (
    <>
      <span className={classes.errorText}>O plano venceu em {dataVencimentoPlanoAsMoment.format('DD/MMM/YY')}</span>.
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function IOSCustomContent({ negocioSessao, ...props }: { negocioSessao: any }) {
  const classes = useStyles()
  const titulo = useMemo(() => {
    if (PlanoEnum.AVALIACAO.id === negocioSessao.plano.id) {
      return 'Período de Avaliação'
    }

    if (negocioSessao.ativo === false) {
      return 'Sistema Desabilitado'
    } else if (PlanoEnum.INDIVIDUAL.id === negocioSessao.plano.id) {
      return PlanoEnum.INDIVIDUAL.nome
    } else if (PlanoEnum.EMPRESA.id === negocioSessao.plano.id) {
      return PlanoEnum.EMPRESA.nome
    }
  }, [negocioSessao.ativo, negocioSessao.plano.id])
  const isPlanoAvaliacao = negocioSessao && negocioSessao.plano.id === PlanoEnum.AVALIACAO.id ? true : false

  return (
    <PanelPage {...props} contentMaxWidth={840} title={isPlanoAvaliacao ? 'Planos' : 'Planos'}>
      <div className={isPlanoAvaliacao ? classes.paperInformacoesPlanoAvaliacao : classes.paperInformacoesPlanoContratado}>
        <Typography variant="h5" style={{ fontSize: 18 }}>
          <b>{titulo}</b>
        </Typography>
        <Typography variant="body1">{<DescricaoValidade negocioSessao={negocioSessao} />}</Typography>
        <br />
        <Typography variant="body2">Para mais informações sobre planos e contratação, acesse a sua conta no site do Salão99 Enterprise pelo navegador de internet.</Typography>
      </div>
    </PanelPage>
  )
}

export default IOSCustomContent
